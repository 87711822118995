/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
  background: #29395b;
  position: relative;
  font-size: 15px;
  overflow: hidden;

  ul {
    list-style: none;
  }

  p {
    color: #e7e7e7;
  }
  li {
    color: #e7e7e7;
  }

  .container {
    position: relative;
  }

  .wpo-upper-footer {
    padding: 40px 0;
  }

  @media (max-width: 991px) {
    .wpo-upper-footer {
      padding: 40px 0 0;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer {
      padding: 40px 0 0;
    }
  }

  @media (max-width: 991px) {
    .wpo-upper-footer .col {
      min-height: 235px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer .col {
      min-height: auto;
      margin-bottom: 60px;
    }
  }

  .widget-title {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    .widget-title {
      margin-bottom: 20px;
    }
  }

  .widget-title {
    h3 {
      font-size: 22px;
      color: $white;
      margin: 0;
      position: relative;
      font-family: $heading-font;

      @media (max-width: 991px) {
        font-size: 20px;
        font-size: 1.25rem;
      }
    }
  }

  .about-widget {
    .logo {
      max-width: 180px;
    }
  }

  .about-widget {
    p {
      margin-bottom: 0.8em;
      line-height: 1.9em;
      color: #e7e7e7;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      overflow: hidden;
      padding-top: 10px;

      li {
        font-size: 22px;
        float: left;

        a {
          color: #e7e7e7;
          width: 36px;
          height: 36px;
          line-height: 40px;
          background: rgba($color: $white, $alpha: 0.1);
          display: block;
          text-align: center;
          @include rounded-border(50%);
          font-size: 18px;

          &:hover {
            color: $dark-gray;
            background: $white;
          }
        }
      }

      li + li {
        margin-left: 25px;
      }
    }
  }

  .wpo-service-link-widget {
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 1200px) {
      padding-right: 0;
      padding-left: 0;
    }
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }

  .link-widget {
    overflow: hidden;
    padding-left: 30px;

    @media (max-width: 991px) {
      padding-left: 0;
    }

    @media (max-width: 767px) {
      max-width: 350px;
    }

    ul {
      li {
        position: relative;
        a {
          color: #e7e7e7;
          font-size: 17px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      li + li {
        padding-top: 15px;
      }
    }
  }

  .contact-ft {
    margin-top: 20px;

    ul {
      li {
        padding-bottom: 15px;
        position: relative;
        padding-left: 35px;
        color: #e5e3e3;
        font-size: 17px;

        i {
          position: absolute;
          left: 0;
          top: 0;
        }

        .fi:before {
          font-size: 20px;
          margin-right: 15px;
        }
      }
    }
  }

  .wpo-lower-footer {
    text-align: center;
    position: relative;
    background: #192847;

    .row {
      padding: 20px 0;
      position: relative;
    }

    .copyright {
      display: inline-block;
      font-size: 15px;
      font-size: 0.9375rem;
      margin: 0;

      a {
        color: $white;
        text-decoration: none;
      }

      @media (max-width: 991px) {
        float: none;
        display: block;
      }
    }
  }

  .instagram {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -3px;

      li {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-bottom: 5px;
        padding: 0px 3px;

        img {
          width: 100%;
          height: 80px;
          object-fit: cover;
        }
      }
    }
  }
}
