.notice-list {
  list-style-type: disc;
}

.notice-list li {
  color: black;
}

.notice-list li a {
  color: black;
  text-decoration: none;
  font-size: medium;
  font-weight: 500;
}

.notice-list li a:hover {
  color: blue;
}

.notice-number {
  margin-right: 5px;
}
