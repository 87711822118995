.slider-container {
  position: relative;
  overflow: hidden;
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

/* Style for navigation buttons */
.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
