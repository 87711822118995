
/*--------------------------------------------------------------
18. wpo-contact-pg-section
--------------------------------------------------------------*/
.wpo-contact-pg-section {
	padding-bottom: 0px;


	.wpo-contact-title{
		max-width: 440px;
		margin: 0 auto;
		text-align: center;
		margin-bottom: 50px;

		@media(max-width:767px){
			margin-bottom: 30px;
		}

		h2{
			font-size: 35px;
			font-size: calc-rem-value(35);
			font-weight: 600;
			margin-bottom: 20px;

			@media(max-width:575px){
				font-size: 25px;
				font-size: calc-rem-value(25);
			}
		}
	}

	form input,
	form select,
	form textarea {
		background: #f4f6fa;
		width: 100%;
		height: 45px;
		border: 1px solid transparent;
		border-radius: 30px;
		box-shadow: none !important;
		padding-left: 25px;

		@include media-query(991px) {
			height: 45px;
		}

		&:focus {
			border-color: $theme-primary-color;
			background: #f4f6fa;
		}
	}

	form textarea {
		height: 180px;
		padding-top: 15px;
	}

	form {
		margin: 0 -15px;
    	overflow: hidden;

		@include placeholder-style(#9d9c9c, 15px, normal);

	    select {
	        display: inline-block;
	        color: #a9a9a9;
	        cursor:pointer;
	        opacity: 1;
	        padding: 6px 25px;
	        font-size: 15px;
	        font-size: calc-rem-value(15);
			appearance:auto;
	    }

		.submit-area{
			text-align: center;
			width: 100%;
			margin-bottom: 10px;
			margin-left: 0;

			@include media-query(767px) {
				margin-bottom: 0;
			}

			.theme-btn{
				border-radius: 30px;
				font-family: $base-font;
				font-size: 16px;

				&:after{
					border-radius: 30px;
				}
			}
		}
	}

	form > div {
	    margin: 0 15px 25px;

		.form-submit{
          text-align: center;
		  margin-top: 30px;
		  margin-bottom: 20px;

		}
		
		.form-field{
			p{
				color: red;
			}
		}
	}

	form .fullwidth {
		width: calc(100% - 25px);
		float: none;
		clear: both;
	}

	.office-info {
		padding-bottom: 100px;

		@media(max-width:767px){
			padding-bottom: 60px;
		}

		.col{

			.office-info-item{
				@media(max-width:1200px){
					margin-bottom: 30px;
				}
			}

			&:last-child{
				.office-info-item{
					@media(max-width:1200px){
						margin-bottom: 0px;
					}
				}
			}

		}

		.office-info-item{
			text-align: center;
			box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
			padding: 40px;

			.office-info-icon{
				width: 85px;
				height: 85px;
				background: #f5f5f5;
				line-height: 85px;
				text-align: center;
				margin: 0 auto;
				@include rounded-border(50%);

				.fi{
					font-size: 30px;   
					color: $theme-primary-color-s2;       
				}
			}
			.office-info-text{
				h2{
					font-size: 30px;
					font-weight: 500;
					margin: 20px 0;
				}
				p{
					margin-bottom: 0;
				}
			}
		}
		
	}

.wpo-contact-form-area{
	padding: 50px;
	background: $white;
	box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
	padding-bottom: 0;
	margin-bottom: -125px;
	position: relative;
	z-index: 99;

	@media(max-width:767px){
		padding: 30px;
		padding-top: 50px;
	}
}
  

}

.wpo-contact-map-section {
	.wpo-contact-map {
	    height: 550px;
	}

    iframe {
	    width: 100%;
	    height: 100%;
	    border: 0;
	    outline: 0;
	}

	h2.hidden{
		display: none;
	}
}